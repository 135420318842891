<div class="title" (click)="toggleVerticalView()" [ngClass]="{ expanded: verticalViewEnabled }">
    <qtm-typography fontWeight="bold" fontSize="7"> Vertical view </qtm-typography>
    @if (verticalViewEnabled) {
        <mat-icon [inline]="true">keyboard_double_arrow_down</mat-icon>
    }
    @if (!verticalViewEnabled) {
        <mat-icon [inline]="true">keyboard_double_arrow_up</mat-icon>
    }
</div>
<div class="main-container" [ngClass]="{ expanded: verticalViewEnabled }" #mainContainer>
    <div class="user-choice">
        <div class="flight-phase">
            <mat-select [(value)]="selectedFlightPhase" (selectionChange)="changeFlightPhase()" disableOptionCentering>
                <mat-select-trigger>
                    <div class="select-trigger">
                        <div class="select-option">
                            <img
                                src="../../../../assets/icons/flight-phases/{{ getPhaseName(selectedFlightPhase.value) }}.svg"
                                alt="Selected flight phase"
                            />
                            {{ selectedFlightPhase.label }}
                        </div>
                        <img matSuffix src="assets/icons/caret.svg" alt="caret" />
                    </div>
                </mat-select-trigger>
                @for (phase of flightPhases; track phase) {
                    <mat-option [value]="phase" [disabled]="!isPhaseAvailable(phase.value) && phase.value !== 0">
                        <div class="select-option">
                            <img src="../../../../assets/icons/flight-phases/{{ getPhaseName(phase.value) }}.svg" alt="Flight phase" />
                            {{ phase.label }}
                        </div>
                    </mat-option>
                }
            </mat-select>
        </div>
        <ffp-toggle
            [toggleItems]="[
                {
                    name: 'gwp100',
                    display: 'GWP<sub>100</sub>'
                },
                {
                    name: 'atr20',
                    display: 'ATR<sub>20</sub>'
                }
            ]"
            (toggledTo)="handleEmissionMeasureChange($event)"
            [toggleValue]="
                coreService.emissionMeasure === 'Gwp100'
                    ? {
                          name: 'gwp100',
                          display: 'GWP<sub>100</sub>'
                      }
                    : {
                          name: 'atr20',
                          display: 'ATR<sub>20</sub>'
                      }
            "
            backgroundColor="grey"
        ></ffp-toggle>
        <div class="flight-selection">
            <mat-checkbox
                [class]="flightFeatureCollection.id"
                [ngClass]="{
                    active: trajectoryCurrentlyDisplayed?.id === flightFeatureCollection.id,
                    inactive: trajectoryCurrentlyDisplayed?.id !== flightFeatureCollection.id,
                    disabled: !flightFeatureCollection.visible
                }"
                (click)="changeTrajectoryDisplayed(flightFeatureCollection)"
            >
                <button>
                    <mat-icon class="material-icons-outlined"> {{ flightFeatureCollection.visible ? "remove_red_eye" : "visibility_off" }}</mat-icon>
                </button>
                <div class="flight-id">
                    <qtm-typography fontSize="7" fontWeight="bold" class="flight-number">
                        {{ flightFeatureCollection.flightNumber }}
                    </qtm-typography>
                    <qtm-typography fontSize="9">
                        {{ flightFeatureCollection.startDate | formatDate: "mm/dd/yy" }}
                        at
                        {{ flightFeatureCollection.startTime | formatHoursAndMinutes }}
                    </qtm-typography>
                </div>
            </mat-checkbox>
            @for (similarFlight of similarFlightFeatureCollection; track similarFlight) {
                <mat-checkbox
                    [class]="similarFlight.id"
                    (click)="changeTrajectoryDisplayed(similarFlight)"
                    [ngClass]="{
                        active: trajectoryCurrentlyDisplayed?.id === similarFlight.id,
                        inactive: trajectoryCurrentlyDisplayed?.id !== similarFlight.id,
                        disabled: !similarFlight.visible
                    }"
                >
                    <button>
                        <mat-icon class="material-icons-outlined"> {{ similarFlight.visible ? "remove_red_eye" : "visibility_off" }}</mat-icon>
                    </button>
                    <div class="flight-id">
                        <qtm-typography fontSize="7" fontWeight="bold" class="flight-number">
                            {{ similarFlight.flightNumber }}
                        </qtm-typography>
                        <qtm-typography fontSize="9">
                            {{ similarFlight.startDate | formatDate }} at
                            {{ similarFlight.startTime | formatHoursAndMinutes }}
                        </qtm-typography>
                    </div>
                </mat-checkbox>
            }
        </div>
    </div>
    <div class="content" #ref>
        @if (emissionsDetails) {
            <div class="legend">
                <qtm-typography class="legend-title" fontWeight="medium" fontSize="9"> Average Emissions </qtm-typography>
                <div class="legend-buttons">
                    <div class="co2 item" [ngClass]="{ disabled: !emissionDisplayed['co2'] }" (click)="changeEmissionDisplayed('co2')">
                        <qtm-typography fontWeight="medium" fontSize="9"> CO<sub>2</sub> </qtm-typography>
                        <div class="value">
                            @if (emissionsDetails) {
                                <qtm-typography fontWeight="bold" fontSize="8">
                                    {{
                                        coreService.emissionMeasure.toLowerCase() === "gwp100"
                                            ? (emissionsDetails.co2! | convertGramsToTons | formatNumber)
                                            : (emissionsDetails.co2! | convertToPicoKelvin | formatNumber)
                                    }}
                                </qtm-typography>
                            }
                            @if (!emissionsDetails) {
                                <div class="no-data"></div>
                            }
                        </div>
                    </div>
                    <div class="nox item" [ngClass]="{ disabled: !emissionDisplayed['nox'] }" (click)="changeEmissionDisplayed('nox')">
                        <qtm-typography fontWeight="medium" fontSize="9"> NO<sub>X</sub> </qtm-typography>
                        <div class="value">
                            @if (emissionsDetails) {
                                <qtm-typography fontWeight="bold" fontSize="8">
                                    {{
                                        coreService.emissionMeasure.toLowerCase() === "gwp100"
                                            ? (emissionsDetails.nox! | convertGramsToTons | formatNumber)
                                            : (emissionsDetails.nox! | convertToPicoKelvin | formatNumber)
                                    }}
                                </qtm-typography>
                            }
                            @if (!emissionsDetails) {
                                <div class="no-data"></div>
                            }
                        </div>
                    </div>
                    <div
                        class="contrails item"
                        [ngClass]="{
                            disabled: !emissionDisplayed['contrails']
                        }"
                        (click)="changeEmissionDisplayed('contrails')"
                    >
                        <qtm-typography fontWeight="medium" fontSize="9"> CONTRAILS </qtm-typography>
                        <div class="value">
                            @if (emissionsDetails) {
                                <qtm-typography fontWeight="bold" fontSize="8">
                                    {{
                                        coreService.emissionMeasure.toLowerCase() === "gwp100"
                                            ? (emissionsDetails.contrails! | convertGramsToTons | formatNumber)
                                            : (emissionsDetails.contrails! | convertToPicoKelvin | formatNumber)
                                    }}
                                </qtm-typography>
                            }
                            @if (!emissionsDetails) {
                                <div class="no-data"></div>
                            }
                        </div>
                    </div>
                    <div
                        class="h2o item"
                        [ngClass]="{
                            disabled: !emissionDisplayed['h2o'],
                            'no-click': emissionsDetails.h2o === 0
                        }"
                        (click)="changeEmissionDisplayed('h2o')"
                    >
                        <qtm-typography fontWeight="medium" fontSize="9"> H<sub>2</sub>O </qtm-typography>
                        <div class="value">
                            @if (emissionsDetails) {
                                <qtm-typography fontWeight="bold" fontSize="8">
                                    {{
                                        coreService.emissionMeasure.toLowerCase() === "gwp100"
                                            ? (emissionsDetails.h2o! | convertGramsToTons | formatNumber)
                                            : (emissionsDetails.h2o! | convertToPicoKelvin | formatNumber)
                                    }}
                                </qtm-typography>
                            }
                            @if (!emissionsDetails) {
                                <div class="no-data"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
