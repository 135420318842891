<div class="container" [ngClass]="{ 'search-mode': openSearchMode }">
    @if (openSearchMode) {
        <ffp-button type="secondary" size="small" gradient class="close-search-mode" (ffpClick)="openSearchMode = false; triggerFilter.emit(false)">
            <mat-icon inline="true">close</mat-icon>
        </ffp-button>
    }
    <form [formGroup]="flightForm">
        <div class="form" [ngClass]="{ 'show-input': areInputShown, 'is-loading': isLoading }">
            @if (isLoading || (isTrajectoryDisplayed && !openSearchMode)) {
                <div class="click-blocker loading-element" (click)="handleClickOnSearchBar()"></div>
            }
            <div
                class="form-item flightNumber"
                [ngClass]="{ focused: focusedElement === 'flightNumber' }"
                #flightNumberOrigin="matAutocompleteOrigin"
                matAutocompleteOrigin
            >
                <label
                    [ngClass]="{
                        'not-empty': flightForm.get('flightNumber')!.value !== ''
                    }"
                    for="flightNumber"
                >
                    <mat-icon inline="true">local_offer</mat-icon>
                    <qtm-typography [fontSize]="focusedElement === 'flightNumber' ? 5 : 6" fontWeight="bold"> Flight number * </qtm-typography>
                </label>
                <div class="form-item-input">
                    <input
                        id="flightNumber"
                        type="text"
                        placeholder="Do you know the flight number?"
                        formControlName="flightNumber"
                        (input)="handleInput($event, autoFlightNumber, triggerFlightNumber)"
                        (keydown)="handleKeyDown($event, autoFlightNumber, triggerFlightNumber)"
                        #triggerFlightNumber="matAutocompleteTrigger"
                        (focus)="setFocusedElement('flightNumber')"
                        (focusout)="setFocusedElement(null, triggerFlightNumber)"
                        [matAutocomplete]="autoFlightNumber"
                        [matAutocompleteConnectedTo]="flightNumberOrigin"
                    />
                    <mat-icon
                        [ngClass]="{
                            show: flightForm.get('flightNumber')!.value !== ''
                        }"
                        class="material-icons-round"
                        (click)="resetAutoInput(autoFlightNumber, 'flightNumber', triggerFlightNumber)"
                        >cancel
                    </mat-icon>
                </div>
                <mat-autocomplete
                    class="climate-analytics custom-scroll"
                    #autoFlightNumber="matAutocomplete"
                    (optionSelected)="fieldsAutocomplete()"
                    [displayWith]="formatAutocompleteOptionText"
                    (opened)="refreshFlights()"
                    (optionsScroll)="loadMoreFlights()"
                >
                    @for (option of flights; track option) {
                        <mat-option [value]="option" class="flight-number-field-option">
                            <div class="option-with-details">
                                <span class="option">
                                    {{ option.flightNumber }}
                                    <mat-icon inline="true">local_offer</mat-icon>
                                </span>
                                <span class="details">
                                    {{ option.startDate | formatDate }}
                                    at
                                    {{ option.startTime | formatHoursAndMinutes }}
                                </span>
                            </div>
                        </mat-option>
                    }
                    @if ((!flightsLoaded || moreFlightsLoading) && errors === null) {
                        <mat-option disabled> Loading... </mat-option>
                    }
                    @if (flightsLoaded && flights.length === 0) {
                        <mat-option disabled> No flight available </mat-option>
                    }
                </mat-autocomplete>
            </div>
            <ffp-button type="primary" size="medium" gradient (ffpClick)="submit()" [disabled]="!flightForm.valid">
                <mat-icon inline="true">search</mat-icon>
            </ffp-button>
        </div>
        @if (!isTrajectoryDisplayed || openSearchMode) {
            <qtm-typography
                fontSize="6"
                fontWeight="bold"
                class="trigger-advanced-search"
                (click)="enableAdvancedSearch()"
                [ngClass]="{
                    'advanced-search-enabled': isAdvancedSearchEnable || openSearchMode
                }"
                [ngStyle]="{ cursor: openSearchMode ? 'default' : 'pointer' }"
            >
                Advanced search
            </qtm-typography>
        }
        @if ((isAdvancedSearchEnable && !isTrajectoryDisplayed) || openSearchMode) {
            <div class="filter" [ngClass]="{ 'show-input': true }">
                <div
                    class="form-item departure"
                    [ngClass]="{ focused: focusedElement === 'departure' }"
                    matAutocompleteOrigin
                    #departureOrigin="matAutocompleteOrigin"
                >
                    <label
                        [ngClass]="{
                            'not-empty': flightForm.get('departure')!.value !== ''
                        }"
                        for="startAirport"
                    >
                        <mat-icon inline="true">flight_takeoff</mat-icon>
                        <qtm-typography [fontSize]="focusedElement === 'departure' ? 5 : 6" fontWeight="bold"> Departure </qtm-typography>
                    </label>
                    <div class="form-item-input">
                        <input
                            id="startAirport"
                            type="text"
                            placeholder="Where did it take off?"
                            formControlName="departure"
                            (input)="handleInput($event, autoFlightDep, triggerFlightDeparture)"
                            (keydown)="handleKeyDown($event, autoFlightDep, triggerFlightDeparture)"
                            #triggerFlightDeparture="matAutocompleteTrigger"
                            (focus)="setFocusedElement('departure')"
                            (focusout)="setFocusedElement(null, triggerFlightDeparture)"
                            [matAutocomplete]="autoFlightDep"
                            [matAutocompleteConnectedTo]="departureOrigin"
                        />
                        <mat-icon
                            [ngClass]="{
                                show: flightForm.get('departure')!.value !== ''
                            }"
                            class="material-icons-round"
                            (click)="resetAutoInput(autoFlightDep, 'departure', triggerFlightDeparture)"
                            >cancel
                        </mat-icon>
                    </div>
                    <mat-autocomplete
                        class="climate-analytics custom-scroll"
                        #autoFlightDep="matAutocomplete"
                        (optionSelected)="handleSelection()"
                        (opened)="refreshFlightFilter('startAirportCode')"
                    >
                        @if (!departureAirportsLoaded && errors === null) {
                            <mat-option disabled> Loading... </mat-option>
                        }
                        @for (option of departureAirportsFiltered; track option) {
                            <mat-option [value]="option">
                                <mat-icon class="material-icons-round">location_city</mat-icon>
                                {{ option }}
                            </mat-option>
                        }
                        @if (departureAirportsLoaded && departureAirportsFiltered.length === 0) {
                            <mat-option disabled> No airport available </mat-option>
                        }
                    </mat-autocomplete>
                </div>
                <div
                    class="form-item-divider"
                    [ngClass]="{
                        hide: focusedElement === 'departure' || focusedElement === 'arrival'
                    }"
                ></div>
                <div
                    class="form-item arrival"
                    [ngClass]="{ focused: focusedElement === 'arrival' }"
                    matAutocompleteOrigin
                    #arrivalOrigin="matAutocompleteOrigin"
                >
                    <label
                        [ngClass]="{
                            'not-empty': flightForm.get('arrival')!.value !== ''
                        }"
                        for="endAirport"
                    >
                        <mat-icon inline="true">flight_land</mat-icon>
                        <qtm-typography [fontSize]="focusedElement === 'arrival' ? 5 : 6" fontWeight="bold"> Arrival </qtm-typography>
                    </label>
                    <div class="form-item-input">
                        <input
                            id="endAirport"
                            placeholder="Where did it land?"
                            (focus)="setFocusedElement('arrival')"
                            (focusout)="setFocusedElement(null, triggerFlightArrival)"
                            (input)="handleInput($event, autoFlightArrival, triggerFlightArrival)"
                            (keydown)="handleKeyDown($event, autoFlightArrival, triggerFlightArrival)"
                            #triggerFlightArrival="matAutocompleteTrigger"
                            formControlName="arrival"
                            [matAutocomplete]="autoFlightArrival"
                            [matAutocompleteConnectedTo]="arrivalOrigin"
                        />
                        <mat-icon
                            [ngClass]="{
                                show: flightForm.get('arrival')!.value !== ''
                            }"
                            class="material-icons-round"
                            (click)="resetAutoInput(autoFlightArrival, 'arrival', triggerFlightArrival)"
                            >cancel
                        </mat-icon>
                    </div>
                    <mat-autocomplete
                        class="climate-analytics custom-scroll"
                        #autoFlightArrival="matAutocomplete"
                        (optionSelected)="handleSelection()"
                        (opened)="refreshFlightFilter('endAirportCode')"
                    >
                        @for (option of arrivalAirportsFiltered; track option) {
                            <mat-option [value]="option">
                                <mat-icon class="material-icons-round">location_city</mat-icon>
                                {{ option }}
                            </mat-option>
                        }
                        @if (arrivalAirportsLoaded && arrivalAirportsFiltered.length === 0) {
                            <mat-option disabled> No airport available </mat-option>
                        }
                        @if (!arrivalAirportsLoaded && errors === null) {
                            <mat-option disabled> Loading... </mat-option>
                        }
                    </mat-autocomplete>
                </div>
                <div
                    class="form-item-divider"
                    [ngClass]="{
                        hide: focusedElement === 'arrival' || focusedElement === 'aircraftType'
                    }"
                ></div>
                <div
                    class="form-item aircraftType"
                    [ngClass]="{ focused: focusedElement === 'aircraftType' }"
                    matAutocompleteOrigin
                    #aircraftTypeOrigin="matAutocompleteOrigin"
                >
                    <label
                        [ngClass]="{
                            'not-empty': flightForm.get('aircraftType')!.value !== ''
                        }"
                        for="aircraftType"
                    >
                        <mat-icon inline="true">airplanemode_active</mat-icon>
                        <qtm-typography [fontSize]="focusedElement === 'aircraftType' ? 5 : 6" fontWeight="bold"> Aircraft Type </qtm-typography>
                    </label>
                    <div class="form-item-input">
                        <input
                            id="aircraftType"
                            placeholder="With which kind of airplane?"
                            (focus)="setFocusedElement('aircraftType')"
                            (focusout)="setFocusedElement(null, triggerAircraftType)"
                            (input)="handleInput($event, autoAircraftType, triggerAircraftType)"
                            (keydown)="handleKeyDown($event, autoAircraftType, triggerAircraftType)"
                            #triggerAircraftType="matAutocompleteTrigger"
                            formControlName="aircraftType"
                            [matAutocomplete]="autoAircraftType"
                            [matAutocompleteConnectedTo]="aircraftTypeOrigin"
                        />
                        <mat-icon
                            [ngClass]="{
                                show: flightForm.get('aircraftType')!.value !== ''
                            }"
                            class="material-icons-round"
                            (click)="resetAutoInput(autoAircraftType, 'aircraftType', triggerAircraftType)"
                            >cancel
                        </mat-icon>
                    </div>
                    <mat-autocomplete
                        class="climate-analytics custom-scroll"
                        #autoAircraftType="matAutocomplete"
                        (optionSelected)="handleSelection()"
                        (opened)="refreshFlightFilter('aircraftType')"
                    >
                        @for (option of aircraftTypesFiltered; track option) {
                            <mat-option [value]="option">
                                <mat-icon class="material-icons-round">airplanemode_active</mat-icon>
                                {{ option }}
                            </mat-option>
                        }
                        @if (aircraftTypesLoaded && aircraftTypesFiltered.length === 0) {
                            <mat-option disabled> No aircraft type available </mat-option>
                        }
                        @if (!aircraftTypesLoaded && errors === null) {
                            <mat-option disabled> Loading... </mat-option>
                        }
                    </mat-autocomplete>
                </div>
                <div
                    class="form-item-divider"
                    [ngClass]="{
                        hide: focusedElement === 'aircraftType' || focusedElement === 'date'
                    }"
                ></div>
                <div class="form-item date" [ngClass]="{ focused: focusedElement === 'date' }">
                    <label
                        [ngClass]="{
                            'not-empty': flightForm.get('date')!.value !== ''
                        }"
                        for="startDate"
                    >
                        <mat-icon inline="true">date_range</mat-icon>
                        <qtm-typography [fontSize]="focusedElement === 'date' ? 5 : 6" fontWeight="bold"> Date </qtm-typography>
                    </label>
                    <div class="form-item-input">
                        <input
                            id="startDate"
                            (click)="refreshFlightFilter('startDate'); picker.open()"
                            [matDatepicker]="picker"
                            (focus)="setFocusedElement('date')"
                            (dateChange)="handleSelection()"
                            (dateInput)="handleSelection()"
                            formControlName="date"
                            readonly
                            matInput
                            placeholder="When did it take place?"
                        />
                        <mat-icon
                            [ngClass]="{
                                show: flightForm.get('date')!.value !== ''
                            }"
                            class="material-icons-round"
                            (click)="resetAutoInput(null, 'date', picker)"
                            >cancel
                        </mat-icon>
                    </div>
                    <mat-datepicker
                        #picker
                        panelClass="search-bar-datepicker"
                        (opened)="setFocusedElement('date')"
                        (closed)="setFocusedElement(null)"
                        [dateClass]="getDateClass()"
                    ></mat-datepicker>
                </div>
                <ffp-button type="primary" size="medium" (ffpClick)="clearForm()" [disabled]="disableClearButton">
                    <mat-icon inline="true" class="material-icons-round">close </mat-icon>
                </ffp-button>
            </div>
        }
    </form>
</div>
