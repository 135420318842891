<div class="container">
    <div
        class="header"
        [ngClass]="{
            'dashboard-header': !currentCityPair,
            'flight-table-header': currentCityPair
        }"
    >
        @if (currentCityPair === null) {
            <div class="text">
                <div class="title">
                    <mat-icon> data_thresholding </mat-icon>
                    <qtm-typography fontWeight="bold" fontSize="5"> Explore data of your city pair </qtm-typography>
                </div>
                <qtm-typography fontWeight="normal" fontSize="8" class="description">
                    Each city pair is displayed below. To learn more about the consumption of each, open the card.
                </qtm-typography>
            </div>
        }
        <div class="navigation-section">
            @if (currentCityPair) {
                <div class="left">
                    <ffp-button type="secondary" size="small" (ffpClick)="back()" neutral>
                        <mat-icon [inline]="true" class="material-icons-round"> arrow_back </mat-icon>
                        <qtm-typography fontWeight="medium" fontSize="8" class="return"> Back to city pair </qtm-typography>
                    </ffp-button>
                </div>
            }
            <div class="right">
                <ffp-toggle
                    [toggleItems]="[
                        {
                            name: 'gwp100',
                            display: 'GWP<sub>100</sub>'
                        },
                        {
                            name: 'atr20',
                            display: 'ATR<sub>20</sub>'
                        }
                    ]"
                    (toggledTo)="handleEmissionMeasureChange($event)"
                    [toggleValue]="
                        coreService.emissionMeasure === 'Gwp100'
                            ? {
                                  name: 'gwp100',
                                  display: 'GWP<sub>100</sub>'
                              }
                            : {
                                  name: 'atr20',
                                  display: 'ATR<sub>20</sub>'
                              }
                    "
                ></ffp-toggle>
                @if (currentCityPair && !isData) {
                    <ffp-button
                        type="secondary"
                        neutral
                        size="small"
                        class="show-on-map"
                        (ffpClick)="showOnMap()"
                        [matBadge]="currentlySelectedFlights.length > 0 && !isTableLoading ? currentlySelectedFlights.length : ''"
                        matBadgeColor="warn"
                        [matTooltip]="currentlySelectedFlights.length > 3 ? '3 flights maximum' : ''"
                        matTooltipClass="climate-analytics-tooltip"
                        [ngClass]="{
                            disabled: currentlySelectedFlights.length > 3
                        }"
                        [disabled]="currentlySelectedFlights.length === 0 || currentlySelectedFlights.length > 3"
                    >
                        <div>
                            <mat-icon inline="true" class="export-icon"> travel_explore </mat-icon>
                            <qtm-typography fontSize="7" fontWeight="medium"> Show on map </qtm-typography>
                        </div>
                    </ffp-button>
                    <ffp-button
                        type="secondary"
                        neutral
                        size="small"
                        class="export"
                        (ffpClick)="exportAsCsv()"
                        [disabled]="currentlySelectedFlights.length === 0"
                        [matBadge]="currentlySelectedFlights.length > 0 && !isTableLoading ? currentlySelectedFlights.length : ''"
                        [ngClass]="{
                            animate: triggerExportButtonAnim,
                            fade: !triggerExportButtonAnim
                        }"
                    >
                        <mat-icon inline="true" class="export-icon">
                            {{ isExported ? "check" : "download" }}
                        </mat-icon>
                        <qtm-typography fontSize="7" fontWeight="medium">
                            {{ isExported ? "Done" : "Export" }}
                        </qtm-typography>
                    </ffp-button>
                }
                @if (!currentCityPair) {
                    <form class="icao-form">
                        <mat-form-field appearance="fill">
                            <mat-icon matPrefix>search</mat-icon>
                            <input
                                type="text"
                                placeholder="Start airport"
                                matInput
                                [formControl]="formControlDeparture"
                                [matAutocomplete]="autoFlightDep"
                            />
                            @if (formControlDeparture.value) {
                                <button type="button" matSuffix aria-label="Clear" (click)="resetDepartureAutoInput()">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            }
                            <mat-autocomplete
                                class="climate-analytics custom-scroll"
                                #autoFlightDep="matAutocomplete"
                                (opened)="refreshFlightFilter('startAirportCode')"
                                (optionSelected)="refreshCards()"
                            >
                                @if (!departureAirportsLoaded && errors === null) {
                                    <mat-option disabled> Loading... </mat-option>
                                }
                                @for (option of departureAirportsFiltered; track option) {
                                    <mat-option [value]="option">
                                        {{ option }}
                                    </mat-option>
                                }
                                @if (departureAirportsLoaded && departureAirportsFiltered.length === 0) {
                                    <mat-option disabled> No airport available </mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-icon matPrefix>search</mat-icon>
                            <input
                                type="text"
                                placeholder="End airport"
                                matInput
                                [formControl]="formControlArrival"
                                [matAutocomplete]="autoFlightArrival"
                            />
                            @if (formControlArrival.value) {
                                <button type="button" matSuffix aria-label="Clear" (click)="resetArrivalAutoInput()">
                                    <mat-icon class="material-icons-round">cancel</mat-icon>
                                </button>
                            }
                            <mat-autocomplete
                                class="climate-analytics custom-scroll"
                                #autoFlightArrival="matAutocomplete"
                                (opened)="refreshFlightFilter('endAirportCode')"
                                (optionSelected)="refreshCards()"
                            >
                                @if (!arrivalAirportsLoaded && errors === null) {
                                    <mat-option disabled> Loading... </mat-option>
                                }
                                @for (option of arrivalAirportsFiltered; track option) {
                                    <mat-option [value]="option">
                                        {{ option }}
                                    </mat-option>
                                }
                                @if (arrivalAirportsLoaded && arrivalAirportsFiltered.length === 0) {
                                    <mat-option disabled> No airport available </mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    </form>
                }
                @if (currentCityPair !== null) {
                    <ffp-button
                        type="secondary"
                        neutral
                        size="small"
                        [disabled]="isTableLoading || currentCityPair!.nbFlights === 0"
                        (ffpClick)="openDialog()"
                    >
                        <mat-icon [inline]="true" [mat-dialog-close]="outputDialogBoxData"> filter_list </mat-icon>
                        <qtm-typography fontWeight="medium" fontSize="7"> Filter </qtm-typography>
                    </ffp-button>
                }
                @if (!coreService.isTactile) {
                    <ffp-button
                        type="secondary"
                        neutral
                        size="small"
                        [disabled]="currentDate.getMonth() === dateDisplayed.getMonth() && currentDate.getFullYear() === dateDisplayed.getFullYear()"
                        (ffpClick)="updateData(currentDate)"
                    >
                        <mat-icon [inline]="true"> calendar_today </mat-icon>
                        <qtm-typography fontWeight="medium" fontSize="7"> This Month </qtm-typography>
                    </ffp-button>
                }
                <div class="manage-month">
                    <ffp-button type="secondary" neutral size="small" (ffpClick)="updateData(createNewDate(-1))">
                        <mat-icon class="material-icons-round" [inline]="true"> arrow_back_ios </mat-icon>
                    </ffp-button>
                    <qtm-typography fontWeight="medium" fontSize="7">
                        {{ dateDisplayed | formatMonthAndYear }}
                    </qtm-typography>
                    <ffp-button
                        type="secondary"
                        neutral
                        size="small"
                        [disabled]="dateDisplayed.getMonth() === currentDate.getMonth() && dateDisplayed.getFullYear() === currentDate.getFullYear()"
                        (ffpClick)="updateData(createNewDate(+1))"
                    >
                        <mat-icon class="material-icons-round" [inline]="true"> arrow_forward_ios </mat-icon>
                    </ffp-button>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        @if (!currentCityPair) {
            <div class="left dashboard-content" [ngClass]="{ 'is-loading': isDashboardLoading }">
                @if (!isDashboardLoading && cards.length !== 0) {
                    <div class="cards-container" (scroll)="loadMoreCards($event)">
                        @for (card of this.cards; track card) {
                            <ffp-analytics-city-pair-card [data]="card" (click)="selectCityPair(card)"></ffp-analytics-city-pair-card>
                        }
                        @if (moreCardsLoading) {
                            <div class="cards-loading">
                                <mat-spinner strokeWidth="5" diameter="50"></mat-spinner>
                            </div>
                        }
                    </div>
                }
                <!-- CARDS LOADER -->
                @if (isDashboardLoading) {
                    <div class="cards-container" style="overflow: hidden">
                        @for (card of loadingCards; track card) {
                            <div class="card loading-element"></div>
                        }
                    </div>
                } @else if (!isDashboardLoading && cards.length === 0 && !errors) {
                    <ffp-no-data
                        title="We are still gathering data"
                        description="Either we are computing your data or we haven't received it yet. <br>
                                   If you see this for the next 4 days, please refer the issue to our team"
                        service="climate-analytics"
                    >
                    </ffp-no-data>
                } @else if (errors !== null) {
                    <ffp-no-data
                        title="Oops, we had an issue!"
                        service="climate-analytics"
                        [errorCode]="errors.status"
                        [button]="true"
                        buttonText="Refresh"
                        buttonIcon="refresh"
                        (buttonCallback)="updateData(dateDisplayed)"
                    >
                    </ffp-no-data>
                }
            </div>
        } @else {
            <div class="left flight-table-content" [ngClass]="{ 'is-loading': isTableLoading }">
                @if (errors === null) {
                    <div
                        class="flight-table-content-header loading-element"
                        [ngClass]="{
                            closed: !isTableHeaderOpen,
                            'no-data': currentCityPair!.nbFlights === 0
                        }"
                    >
                        <div class="top">
                            <div class="left">
                                <div class="airports">
                                    <qtm-typography fontWeight="bold" fontSize="4">
                                        {{ currentCityPair!.startAirportCode }}
                                    </qtm-typography>
                                    <qtm-typography fontWeight="bold" fontSize="4">
                                        {{ currentCityPair!.endAirportCode }}
                                    </qtm-typography>
                                </div>
                                <div class="tags">
                                    <div class="tag nb-flight">
                                        <mat-icon class="material-icons-round" [inline]="true"> flight </mat-icon>
                                        <qtm-typography fontWeight="medium" fontSize="8">
                                            {{
                                                currentCityPair!.nbFlights > 1
                                                    ? currentCityPair!.nbFlights + " flights"
                                                    : currentCityPair!.nbFlights + " flight"
                                            }}
                                        </qtm-typography>
                                    </div>
                                    @for (aircraftType of cityPairAircraftTypeCounter; track aircraftType; let i = $index) {
                                        <div class="tag aircraft-type">
                                            <qtm-typography fontWeight="medium" fontSize="8">
                                                {{ aircraftType.number }}
                                            </qtm-typography>
                                            <qtm-typography fontWeight="medium" fontSize="8">
                                                {{ aircraftType.name }}
                                            </qtm-typography>
                                        </div>
                                    }
                                </div>
                            </div>
                            @if (currentCityPair!.nbFlights !== 0) {
                                <div class="right">
                                    <ffp-button type="primary" size="small" neutral (ffpClick)="isTableHeaderOpen = !isTableHeaderOpen">
                                        <mat-icon> expand_less </mat-icon>
                                    </ffp-button>
                                </div>
                            }
                        </div>
                        @if (currentCityPair!.nbFlights !== 0) {
                            <div class="bottom">
                                <div class="bottom-left">
                                    <qtm-typography fontWeight="medium" fontSize="7" class="title"> Total climate effects details </qtm-typography>
                                    <div class="values">
                                        <div class="consumption-details-legend">
                                            <div class="co2 item">
                                                <qtm-typography fontWeight="medium" fontSize="9"> CO<sub>2</sub> </qtm-typography>
                                                @if (coreService.emissionMeasure === "Ccf") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalCcfCo2 | convertToPicoKelvin | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit"> picoKelvin </qtm-typography>
                                                    </div>
                                                }
                                                @if (coreService.emissionMeasure === "Gwp100") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalGwp100Co2 | convertGramsToTons | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit">
                                                            t CO<sub>2</sub>-eq
                                                        </qtm-typography>
                                                    </div>
                                                }
                                            </div>
                                            <div class="nox item">
                                                <qtm-typography fontWeight="medium" fontSize="9"> NO<sub>X</sub> </qtm-typography>
                                                @if (coreService.emissionMeasure === "Ccf") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalCcfNox | convertToPicoKelvin | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit"> picoKelvin </qtm-typography>
                                                    </div>
                                                }
                                                @if (coreService.emissionMeasure === "Gwp100") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalGwp100Nox | convertGramsToTons | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit">
                                                            t CO<sub>2</sub>-eq
                                                        </qtm-typography>
                                                    </div>
                                                }
                                            </div>
                                            <div class="contrails item">
                                                <qtm-typography fontWeight="medium" fontSize="9"> CONTRAILS </qtm-typography>
                                                @if (coreService.emissionMeasure === "Ccf") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalCcfContrails | convertToPicoKelvin | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit"> picoKelvin </qtm-typography>
                                                    </div>
                                                }
                                                @if (coreService.emissionMeasure === "Gwp100") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalGwp100Contrails | convertGramsToTons | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit">
                                                            t CO<sub>2</sub>-eq
                                                        </qtm-typography>
                                                    </div>
                                                }
                                            </div>
                                            <div class="h2o item">
                                                <qtm-typography fontWeight="medium" fontSize="9"> H<sub>2</sub>O </qtm-typography>
                                                @if (coreService.emissionMeasure === "Ccf") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalCcfH2o | convertToPicoKelvin | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit"> picoKelvin </qtm-typography>
                                                    </div>
                                                }
                                                @if (coreService.emissionMeasure === "Gwp100") {
                                                    <div class="value">
                                                        <qtm-typography fontWeight="bold" fontSize="8">
                                                            {{ currentCityPair!.totalGwp100H2o | convertGramsToTons | formatNumber }}
                                                        </qtm-typography>
                                                        <qtm-typography fontWeight="regular" fontSize="9" class="unit">
                                                            t CO<sub>2</sub>-eq
                                                        </qtm-typography>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div class="emissions">
                                            @if (coreService.emissionMeasure === "Ccf") {
                                                <div class="value">
                                                    <qtm-typography fontWeight="medium" fontSize="4" class="total-emissions">
                                                        {{ currentCityPair!.totalCcf | convertToPicoKelvin | formatNumber }}
                                                    </qtm-typography>
                                                    <qtm-typography fontWeight="regular" fontSize="9" class="unit"> picoKelvin </qtm-typography>
                                                </div>
                                            }
                                            @if (coreService.emissionMeasure === "Gwp100") {
                                                <div class="value">
                                                    <qtm-typography fontWeight="medium" fontSize="4" class="total-emissions">
                                                        {{ currentCityPair!.totalGwp100 | convertGramsToTons | formatNumber }}
                                                    </qtm-typography>
                                                    <qtm-typography fontWeight="regular" fontSize="9" class="unit">
                                                        t CO<sub>2</sub>-eq
                                                    </qtm-typography>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    @if (currentCityPair!.nbFlights > 0 && coreService.emissionMeasure === "Ccf") {
                                        <ffp-consumption-details
                                            [tooltip]="false"
                                            [data]="{
                                                co2: currentCityPair!.totalCcfCo2 / 1e-12 || 0,
                                                h2o: currentCityPair!.totalCcfH2o / 1e-12 || 0,
                                                nox: currentCityPair!.totalCcfNox / 1e-12 || 0,
                                                contrails: currentCityPair!.totalCcfContrails / 1e-12 || 0
                                            }"
                                            [svgId]="currentCityPair!.startAirportCode + '-' + currentCityPair!.endAirportCode"
                                            [parentWidth]="getElementWidth('.bottom-left')"
                                        >
                                        </ffp-consumption-details>
                                    }
                                    @if (currentCityPair!.nbFlights > 0 && coreService.emissionMeasure === "Gwp100") {
                                        <ffp-consumption-details
                                            [tooltip]="false"
                                            [data]="{
                                                co2: currentCityPair!.totalGwp100Co2 || 0,
                                                h2o: currentCityPair!.totalGwp100H2o || 0,
                                                nox: currentCityPair!.totalGwp100Nox || 0,
                                                contrails: currentCityPair!.totalGwp100Contrails || 0
                                            }"
                                            [svgId]="currentCityPair!.startAirportCode + '-' + currentCityPair!.endAirportCode"
                                            [parentWidth]="getElementWidth('.bottom-left')"
                                        >
                                        </ffp-consumption-details>
                                    }
                                </div>
                                <div class="bottom-right">
                                    <qtm-typography fontWeight="medium" fontSize="7" class="title">
                                        {{ dateDisplayed | getMonth }}'s average
                                    </qtm-typography>
                                    <div class="emissions">
                                        @if (coreService.emissionMeasure === "Ccf") {
                                            <div class="value">
                                                <qtm-typography fontWeight="medium" fontSize="4" class="total-emissions">
                                                    {{ currentCityPair!.totalCcf / currentCityPair!.nbFlights | convertToPicoKelvin | formatNumber }}
                                                </qtm-typography>
                                                <qtm-typography fontWeight="regular" fontSize="9" class="unit"> picoKelvin </qtm-typography>
                                            </div>
                                        }
                                        @if (coreService.emissionMeasure === "Gwp100") {
                                            <div class="value">
                                                <qtm-typography fontWeight="medium" fontSize="4" class="total-emissions">
                                                    {{
                                                        currentCityPair!.totalGwp100 / currentCityPair!.nbFlights | convertGramsToTons | formatNumber
                                                    }}
                                                </qtm-typography>
                                                <qtm-typography fontWeight="regular" fontSize="9" class="unit"> t CO<sub>2</sub>-eq </qtm-typography>
                                            </div>
                                        }
                                    </div>
                                    <div class="delta">
                                        @if (currentCityPair!.ccfMonthDelta && coreService.emissionMeasure === "Ccf") {
                                            <ffp-data-delta [value]="currentCityPair!.ccfMonthDelta">
                                                {{
                                                    currentCityPair!.ccfMonthDelta > 0
                                                        ? "+" + currentCityPair!.ccfMonthDelta
                                                        : currentCityPair!.ccfMonthDelta
                                                }}
                                                % ATR<sub>20</sub>
                                            </ffp-data-delta>
                                        }
                                        @if (currentCityPair!.gwp100MonthDelta && coreService.emissionMeasure === "Gwp100") {
                                            <ffp-data-delta [value]="currentCityPair!.gwp100MonthDelta">
                                                {{
                                                    currentCityPair!.gwp100MonthDelta > 0
                                                        ? "+" + currentCityPair!.gwp100MonthDelta
                                                        : currentCityPair!.gwp100MonthDelta
                                                }}
                                                % GWP<sub>100</sub>
                                            </ffp-data-delta>
                                        }
                                        <div class="description">
                                            <div class="first-line">
                                                @if (currentCityPair!.ccfMonthDelta) {
                                                    <qtm-typography fontSize="9" fontWeight="medium"> in comparison </qtm-typography>
                                                }
                                            </div>
                                            <div class="second-line">
                                                @if (currentCityPair!.ccfMonthDelta) {
                                                    <qtm-typography fontSize="9" fontWeight="medium"> with last month </qtm-typography>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                @if (!isTableLoading && currentCityPair!.nbFlights! !== 0) {
                    <ffp-analytics-flight-table
                        [data]="currentCityPair!"
                        [inputFilters]="outputDialogBoxData!"
                        [inputFlights]="flightsTableFlights"
                        [isHeaderOpened]="isTableHeaderOpen"
                        (noData)="checkData($event)"
                        [(currentlySelectedFlights)]="currentlySelectedFlights"
                        (initialDataDialogBox)="setOutputFilters($event)"
                    >
                    </ffp-analytics-flight-table>
                }
                <!-- TABLE LOADER -->
                @if (isTableLoading) {
                    <div class="table-loader loading-element"></div>
                }
                <!-- FLIGHT TABLE ERRORS -->
                @if (currentCityPair!.nbFlights! === 0 && !errors && !isTableLoading && flightsTableFlights.length === 0) {
                    <ffp-no-data
                        title="We looked deep in the cloud, and didn’t find any flight"
                        description="Seems, there is no flight from this city pair at this date / filter. <br>
                               Either we are computing your data or we didn’t receive it yet. <br>
                               If you know you had flights and see this for the next 4 days, <br>
                               please refer the issue to our team"
                        service="climate-analytics"
                        [button]="true"
                        buttonIcon="mail"
                        buttonText="Contact us"
                        (buttonCallback)="coreService.mailTo()"
                    >
                    </ffp-no-data>
                }
                @if (errors !== null) {
                    <ffp-no-data
                        title="Oops, we had an issue!"
                        service="climate-analytics"
                        [errorCode]="errors.status ? errors.status : $any(errors).lineno"
                        [button]="true"
                        buttonText="Refresh"
                        buttonIcon="refresh"
                        (buttonCallback)="selectCityPair(currentCityPair!)"
                    >
                    </ffp-no-data>
                }
            </div>
        }
        <div class="right">
            <ffp-analytics-coming-soon></ffp-analytics-coming-soon>
        </div>
    </div>
</div>
