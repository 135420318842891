@if (isHovered && trajId !== "flight") {
    <ffp-button type="primary" size="small" neutral (ffpClick)="removeCard()">
        <mat-icon class="material-icons-round"> close </mat-icon>
    </ffp-button>
}

<div
    class="container"
    [ngClass]="{
        'is-best-flight': isBestFlight,
        highlighted: highlighted,
        invisible: !isVisible
    }"
    [class]="trajId"
>
    <div class="header">
        <div class="left">
            <button
                (click)="toggleVisibility()"
                [disabled]="
                    climateAnalyticsService.getVisibleTrajectories().length === 1 && climateAnalyticsService.getVisibleTrajectories()[0] === trajId
                "
                [ngClass]="{ 'visibility-off': !isVisible }"
            >
                <mat-icon class="material-icons-outlined">{{ isVisible ? "remove_red_eye" : "visibility_off" }}</mat-icon>
            </button>
            <div class="flight-id">
                <qtm-typography fontSize="7" fontWeight="bold" class="flight-number">
                    {{ selectedFlight.flightNumber }}
                </qtm-typography>
                <div class="flight-date">
                    <qtm-typography fontSize="9" fontWeight="medium">
                        {{ selectedFlight.startDate | formatDate }} at
                        {{ selectedFlight.startTime | formatHoursAndMinutes }}
                    </qtm-typography>
                </div>
            </div>
        </div>

        @if (isBestFlight) {
            <div class="best-flight">
                <mat-icon class="material-icons-round">star</mat-icon>
                <qtm-typography fontSize="8" fontWeight="medium"> Best </qtm-typography>
            </div>
        }

        @if (coreService.emissionMeasure === "Ccf" && selectedFlight.comparisonCriteriaCcf) {
            <div class="right">
                <div class="top">
                    <ffp-data-delta [value]="selectedFlight.comparisonCriteriaCcf" [filled]="isBestFlight">
                        {{
                            selectedFlight.comparisonCriteriaCcf > 0
                                ? "+" + selectedFlight.comparisonCriteriaCcf
                                : selectedFlight.comparisonCriteriaCcf
                        }}% ATR<sub>20</sub>
                    </ffp-data-delta>
                    <qtm-typography fontSize="9" fontWeight="medium"> in comparison with </qtm-typography>
                </div>
                <qtm-typography fontSize="9" fontWeight="medium"> the average ATR<sub>20</sub> for this city pair </qtm-typography>
            </div>
        }
        @if (coreService.emissionMeasure === "Ccf" && selectedFlight.comparisonCriteriaCcf === 0) {
            <div class="right">
                <div class="top">
                    <qtm-typography fontSize="9" fontWeight="medium">
                        The ATR<sub>20</sub> emitted by this flight <br />
                        is equal to the average for this city pair
                    </qtm-typography>
                </div>
            </div>
        }
        @if (coreService.emissionMeasure === "Gwp100" && selectedFlight.comparisonCriteriaGwp100) {
            <div class="right">
                <div class="top">
                    <ffp-data-delta [value]="selectedFlight.comparisonCriteriaGwp100" [filled]="isBestFlight">
                        {{
                            selectedFlight.comparisonCriteriaGwp100 > 0
                                ? "+" + selectedFlight.comparisonCriteriaGwp100
                                : selectedFlight.comparisonCriteriaGwp100
                        }}% GWP<sub>100</sub>
                    </ffp-data-delta>
                    <qtm-typography fontSize="9" fontWeight="medium"> in comparison with </qtm-typography>
                </div>
                <qtm-typography fontSize="9" fontWeight="medium"> the average GWP<sub>100</sub> for this city pair </qtm-typography>
            </div>
        }
        @if (coreService.emissionMeasure === "Gwp100" && selectedFlight.comparisonCriteriaGwp100 === 0) {
            <div class="right">
                <div class="top">
                    <qtm-typography fontSize="9" fontWeight="medium">
                        The GWP<sub>100</sub> emitted by this flight <br />
                        is equal to the average for this city pair
                    </qtm-typography>
                </div>
            </div>
        }
    </div>
    <div class="content">
        <div class="emissions-info">
            <div class="title">
                <img src="assets/icons/emissions.svg" alt="Emissions info icons" />
                <qtm-typography fontSize="9" fontWeight="medium"> EMISSIONS INFO </qtm-typography>
            </div>
            <div class="first-row">
                <div class="item">
                    <qtm-typography fontSize="9" fontWeight="regular" class="label"> CONTRAILS DISTANCE </qtm-typography>
                    <div class="contrails-info">
                        <div class="contrails-bar-legend">
                            <qtm-typography
                                fontSize="10"
                                fontWeight="regular"
                                [ngStyle]="{
                                    'margin-left': warning.offsetLeft + 10 + 'px'
                                }"
                            >
                                20%
                            </qtm-typography>
                            <qtm-typography
                                fontSize="10"
                                fontWeight="regular"
                                [ngStyle]="{
                                    'margin-left': alert.offsetLeft + 10 + 'px'
                                }"
                            >
                                50%
                            </qtm-typography>
                        </div>
                        <div class="contrails-bar">
                            <qtm-typography fontSize="10" fontWeight="medium"> 0% </qtm-typography>
                            <div class="contrails-bar-content">
                                <div
                                    class="pointer"
                                    [ngStyle]="{
                                        left: 'calc(' + percentageUnderContrails + '% - 4px)'
                                    }"
                                ></div>
                                <div class="good"></div>
                                <div class="warning" #warning></div>
                                <div class="alert" #alert></div>
                            </div>
                            <qtm-typography fontSize="10" fontWeight="medium"> 100% </qtm-typography>
                        </div>
                        <qtm-typography fontSize="9" fontWeight="regular" class="value">
                            <strong>{{ percentageUnderContrails }}</strong
                            >% =
                            <strong>{{ selectedFlight.totalMetersContrails | convertMetersToNM }}</strong>
                            NM
                        </qtm-typography>
                    </div>
                </div>
                <div class="item">
                    <qtm-typography fontSize="9" fontWeight="regular" class="label"> FUEL </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="regular" class="value">
                        <strong>{{ selectedFlight.totalFuelBurnt | convertGramsToTons }}</strong>
                        t
                    </qtm-typography>
                    @if (displayCO2Info && !!totalCO2PerPAX) {
                        <div class="item">
                            <qtm-typography fontSize="9" fontWeight="regular" class="label"> CO<sub>2</sub> </qtm-typography>
                            <qtm-typography fontSize="9" fontWeight="regular" class="value">
                                <strong>{{ totalCO2! | convertGramsToTons }}</strong>
                                t
                            </qtm-typography>
                        </div>
                    }
                </div>
                @if (!(displayCO2Info && !!totalCO2PerPAX)) {
                    <div class="item">
                        <qtm-typography fontSize="9" fontWeight="regular" class="label"> CO<sub>2</sub> </qtm-typography>
                        <qtm-typography fontSize="9" fontWeight="regular" class="value">
                            <strong>{{ selectedFlight.totalCo2Emitted | convertGramsToTons }}</strong>
                            t
                        </qtm-typography>
                    </div>
                }
                @if (coreService.emissionMeasure !== "Gwp100") {
                    <div class="item">
                        <qtm-typography fontSize="9" fontWeight="regular" class="label"> ATR<sub>20</sub> </qtm-typography>
                        <qtm-typography fontSize="9" fontWeight="regular" class="value">
                            <strong>{{ selectedFlight.totalCcfAbs | convertToPicoKelvin | formatNumber }}</strong>
                            pK
                        </qtm-typography>
                        @if (displayCO2Info && !!totalCO2PerPAX) {
                            <div class="item">
                                <qtm-typography fontSize="9" fontWeight="regular" class="label"> CO<sub>2</sub> / PAX </qtm-typography>
                                <qtm-typography fontSize="9" fontWeight="regular" class="value">
                                    <strong>{{ (totalCO2PerPAX | autoConvert: "g" : "value")! | formatNumber }}</strong>
                                    {{ totalCO2PerPAX | autoConvert: "g" : "unit" }}
                                </qtm-typography>
                            </div>
                        }
                    </div>
                }
                @if (coreService.emissionMeasure === "Gwp100") {
                    <div class="item">
                        <qtm-typography fontSize="9" fontWeight="regular" class="label"> GWP<sub>100</sub> </qtm-typography>
                        <qtm-typography fontSize="9" fontWeight="regular" class="value">
                            <strong>{{ selectedFlight.totalGwp100 | convertGramsToTons | formatNumber }}</strong>
                            t eq CO2
                        </qtm-typography>
                        @if (displayCO2Info && !!totalCO2PerPAX) {
                            <div class="item">
                                <qtm-typography fontSize="9" fontWeight="regular" class="label"> CO<sub>2</sub> / PAX </qtm-typography>
                                <qtm-typography fontSize="9" fontWeight="regular" class="value">
                                    <strong>{{ (totalCO2PerPAX | autoConvert: "g" : "value")! | formatNumber }}</strong>
                                    {{ totalCO2PerPAX | autoConvert: "g" : "unit" }}
                                </qtm-typography>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>

        <div class="flight-info">
            <div class="title">
                <mat-icon class="material-icons-round" [inline]="true"> flight </mat-icon>
                <qtm-typography fontSize="9" fontWeight="medium"> FLIGHT INFO </qtm-typography>
            </div>
            <div class="first-row">
                <div class="item">
                    <qtm-typography fontSize="9" fontWeight="regular" class="label"> TYPE </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="regular" class="value">
                        <strong>{{ selectedFlight.aircraftType }}</strong>
                    </qtm-typography>
                </div>
                <div class="item">
                    <qtm-typography fontSize="9" fontWeight="regular" class="label">
                        {{ selectedFlight.cruiseSpeed ? "CRUISE SPEED" : "MAX SPEED" }}
                    </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="regular" class="value">
                        <strong>{{
                            selectedFlight.cruiseSpeed > 0
                                ? (selectedFlight.cruiseSpeed | number: "1.1-2")
                                : ((flightAvgSpeed! * 1000) / 340.29 | number: "1.1-2")
                        }}</strong>
                        Mach
                    </qtm-typography>
                </div>
                <div class="item">
                    <qtm-typography fontSize="9" fontWeight="regular" class="label"> MAX. ALT. </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="regular" class="value">
                        <strong>{{ flightMaxAltitude }}</strong>
                    </qtm-typography>
                </div>
                <div class="item">
                    <qtm-typography fontSize="9" fontWeight="regular" class="label"> DISTANCE </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="regular" class="value">
                        <strong>{{ flightDistance! | convertMetersToNM }}</strong>
                        NM
                    </qtm-typography>
                </div>
                <div class="item">
                    <qtm-typography fontSize="9" fontWeight="regular" class="label"> DURATION </qtm-typography>
                    <qtm-typography fontSize="9" fontWeight="regular" class="value">
                        <strong>{{ flightDuration! | convertMsToHoursAndMinutes }}</strong>
                    </qtm-typography>
                </div>
            </div>
        </div>
    </div>
</div>
