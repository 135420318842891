<div class="coming-flights-header">
    <div class="left">
        <ffp-button
            type="secondary"
            neutral
            size="small"
            class="today-flights loading-element"
            [disabled]="isCurrentDay"
            (ffpClick)="displayCurrentDay()"
        >
            <mat-icon class="material-icons" [inline]="true">today</mat-icon>
            <qtm-typography fontSize="8" fontWeight="medium"> Today's flights </qtm-typography>
        </ffp-button>
        <div class="manage-day">
            <ffp-button type="secondary" neutral size="small" (ffpClick)="back(24)" [disabled]="counter < 24">
                <mat-icon class="material-icons-round" [inline]="true">arrow_back_ios</mat-icon>
            </ffp-button>
            <ffp-button type="secondary" neutral size="small" (ffpClick)="next(24)" [disabled]="counter >= 48">
                <mat-icon class="material-icons-round" [inline]="true">arrow_forward_ios</mat-icon>
            </ffp-button>
            @if (!isCurrentDay) {
                <qtm-typography component="body-2" fontSize="8" fontWeight="medium" class="new-day">
                    {{ displayedDay | formatFullDate }}
                </qtm-typography>
            }
        </div>
    </div>
    <div class="right">
        <button
            class="manage-window-time"
            (mouseover)="setTriggerTimeWindowButtonAnim(true)"
            (mouseleave)="setTriggerTimeWindowButtonAnim(false)"
            [ngClass]="{ animate: triggerTimeWindowButtonAnim }"
        >
            <span class="manage-window-time-icon" [ngClass]="{ animate: triggerTimeWindowButtonAnim }">
                <mat-icon class="material-icons-sharp">timer</mat-icon>
            </span>
            <qtm-typography
                fontSize="8"
                fontWeight="medium"
                (click)="changeViewMode(6)"
                [ngClass]="{
                    'window-time-selected': windowTime === 6,
                    animate: triggerTimeWindowButtonAnim
                }"
            >
                6h
            </qtm-typography>

            <qtm-typography
                fontSize="8"
                fontWeight="medium"
                (click)="changeViewMode(12)"
                [ngClass]="{
                    'window-time-selected': windowTime === 12,
                    animate: triggerTimeWindowButtonAnim
                }"
            >
                12h
            </qtm-typography>

            <qtm-typography
                fontSize="8"
                fontWeight="medium"
                (click)="changeViewMode(24)"
                [ngClass]="{
                    'window-time-selected': windowTime === 24,
                    animate: triggerTimeWindowButtonAnim
                }"
            >
                24h
            </qtm-typography>
        </button>
        <ffp-button type="secondary" neutral size="small" (ffpClick)="fullScreen()">
            <mat-icon class="material-icons-round" [inline]="true">{{ isFullScreen ? "fullscreen_exit" : "fullscreen" }}</mat-icon>
        </ffp-button>
    </div>
</div>
@if (coreService.getCurrentDate()) {
    <div
        class="timetable"
        [ngStyle]="{
            background:
                'linear-gradient(to right, ' +
                sassHelperService.getProperty('ffp-grey-75') +
                ' ' +
                (
                    (100 *
                        (coreService.getCurrentDate().getHours() * 60 +
                            coreService.getCurrentDate().getMinutes() -
                            (checkpoint.getHours() + counter) * 60)) /
                    (windowTime * 60)
                ).toString() +
                '%,' +
                sassHelperService.getProperty('ffp-grey-50') +
                ' 0%)'
        }"
    >
        <div class="black-filter" [ngClass]="{ show: currentFlight !== null || isFilterActive }" (click)="closeDetails()"></div>
        <div class="content">
            @if (coreService.getCurrentDate().getDay() === displayedDay.getDay()) {
                <div
                    class="current-time"
                    [ngStyle]="{
                        'margin-left':
                            'calc(' +
                            (
                                (100 *
                                    (coreService.getCurrentDate()!.getHours() * 60 +
                                        coreService.getCurrentDate()!.getMinutes() -
                                        (checkpoint!.getHours() + counter) * 60)) /
                                (windowTime * 60)
                            ).toString() +
                            '% - 10px)'
                    }"
                    [ngClass]="{
                        'slide-in-left': lastNavigation === 'left'
                    }"
                >
                    <div class="current-time-legend">
                        <qtm-typography component="body-2" fontSize="8" fontWeight="black">
                            {{ coreService.getCurrentDate() | formatCurrentTime }}
                        </qtm-typography>
                    </div>
                    <svg width="6" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="3" y1="1000" x2="3" y2="6" stroke="#608020" stroke-width="2" stroke-linecap="round" />
                        <circle cx="3" cy="3" r="3" fill="#608020" />
                    </svg>
                </div>
            }
            @if (windowTime !== 24) {
                <div class="nav-buttons">
                    <ffp-button
                        class="ffp-navigation-button small left"
                        type="secondary"
                        neutral
                        size="small"
                        (ffpMouseover)="leftButtonHover = true"
                        (ffpMouseleave)="leftButtonHover = false"
                        [disabled]="counter === 0"
                        (ffpClick)="counter !== 0 && back()"
                        #leftButton
                        [ngClass]="{
                            expanded: leftButtonHover && !leftButton.disabled
                        }"
                    >
                        <mat-icon class="material-icons-round" [inline]="true">arrow_back_ios</mat-icon>
                        <qtm-typography component="body-1" fontSize="7" fontWeight="bold">
                            {{ windowTime === 24 ? "Yesterday" : "Previous " + windowTime + " hours" }}
                        </qtm-typography>
                    </ffp-button>
                    <ffp-button
                        type="secondary"
                        neutral
                        size="small"
                        class="ffp-navigation-button small right"
                        (ffpMouseover)="rightButtonHover = true"
                        (ffpMouseleave)="rightButtonHover = false"
                        (ffpClick)="counter !== 72 && counter + windowTime !== 72 && next()"
                        [disabled]="counter === 72 || counter + windowTime === 72"
                        #rightButton
                        [ngClass]="{
                            expanded: rightButtonHover && !rightButton.disabled
                        }"
                    >
                        <qtm-typography component="body-1" fontSize="7" fontWeight="bold">
                            {{ windowTime === 24 ? "Tomorrow" : "Next " + windowTime + " hours" }}
                        </qtm-typography>
                        <mat-icon class="material-icons-round" [inline]="true">arrow_forward_ios</mat-icon>
                    </ffp-button>
                </div>
            }
            @for (value of displayedHours; track value; let isFirst = $first; let isLast = $last; let i = $index) {
                <div
                    [ngStyle]="{
                        width: 'calc(' + ((windowTime / (windowTime / (windowTime / 6))) * 100) / windowTime + '%)'
                    }"
                    class="time-section"
                    [ngClass]="{
                        'slide-in-left': value.class === 'slide-in-left',
                        'slide-in-right': value.class === 'slide-in-right',
                        'slide-out-left': value.class === 'slide-out-left',
                        'slide-out-right': value.class === 'slide-out-right'
                    }"
                >
                    @if (!isFirst) {
                        <div class="legend">
                            @if (
                                value.value.toLocaleString("en-US", {
                                    day: "numeric",
                                    timeZone: "UTC"
                                }) === displayedHours[i - 1].value.toLocaleString("en-US", { day: "numeric", timeZone: "UTC" })
                            ) {
                                <qtm-typography component="body-2" fontSize="8" fontWeight="bold">
                                    {{ value.value | formatTime }}
                                </qtm-typography>
                            }
                            @if (
                                value.value.toLocaleString("en-US", {
                                    day: "numeric",
                                    timeZone: "UTC"
                                }) !== displayedHours[i - 1].value.toLocaleString("en-US", { day: "numeric", timeZone: "UTC" })
                            ) {
                                <qtm-typography component="body-2" fontSize="8" fontWeight="bold" class="new-day">
                                    {{ value.value | getDate }}
                                </qtm-typography>
                            }
                        </div>
                    }
                </div>
            }
            @if (isFlightLineComputed) {
                <div class="flights">
                    @for (flight of flightsDisplayed; track flight; let i = $index) {
                        <ffp-forecast-flight-tag
                            id="flight-tag-{{ flight.index }}"
                            [flight]="flight!"
                            [ngStyle]="{
                                width: 'calc(' + flight!.width + '%)',
                                left: 'calc(' + flight!.marginLeft + '%)',
                                top: 84 * flight.initialFlight.line + 'px'
                            }"
                            [ngClass]="{
                                'x-small':
                                    (getFlightTagWidthPx(flight) < 120 && flight.initialFlight.hasBestAlternative) ||
                                    (getFlightTagWidthPx(flight) < 80 && !flight.initialFlight.hasBestAlternative),
                                small:
                                    (getFlightTagWidthPx(flight) < 240 && flight.initialFlight.hasBestAlternative) ||
                                    (getFlightTagWidthPx(flight) < 190 && !flight.initialFlight.hasBestAlternative),
                                medium:
                                    (getFlightTagWidthPx(flight) >= 240 &&
                                        getFlightTagWidthPx(flight) < 370 &&
                                        flight.initialFlight.hasBestAlternative) ||
                                    (getFlightTagWidthPx(flight) >= 190 &&
                                        getFlightTagWidthPx(flight) < 350 &&
                                        !flight.initialFlight.hasBestAlternative),
                                large:
                                    (getFlightTagWidthPx(flight) >= 370 &&
                                        getFlightTagWidthPx(flight) < 475 &&
                                        flight.initialFlight.hasBestAlternative) ||
                                    (getFlightTagWidthPx(flight) >= 350 &&
                                        getFlightTagWidthPx(flight) < 435 &&
                                        !flight.initialFlight.hasBestAlternative),
                                'x-large':
                                    (getFlightTagWidthPx(flight) >= 475 && flight.initialFlight.hasBestAlternative) ||
                                    (getFlightTagWidthPx(flight) >= 435 && !flight.initialFlight.hasBestAlternative),
                                'overflowing-left': flight.isOverflowingLeft,
                                'overflowing-right': flight.isOverflowingRight,
                                'no-button': getFlightTagWidthPx(flight) <= 65 && flight.initialFlight.hasBestAlternative,
                                'display-none': flight.width! <= 2,
                                'display-fn':
                                    (getFlightTagWidthPx(flight) < 120 && flight.initialFlight.hasBestAlternative) ||
                                    (getFlightTagWidthPx(flight) <= 75 && !flight.initialFlight.hasBestAlternative),
                                fadeIn: !flight.class,
                                'slide-in-left': flight.class === 'slide-in-left',
                                'slide-in-right': flight.class === 'slide-in-right',
                                'slide-out-left': flight.class === 'slide-out-left',
                                'slide-out-right': flight.class === 'slide-out-right'
                            }"
                            [isPassed]="flight.initialFlight.realEndDate.getTime() < coreService.getCurrentDate()!.getTime()"
                            [elapsedTimeWidth]="getElapsedTimeWidth(flight)"
                        ></ffp-forecast-flight-tag>
                    }
                </div>
            }
        </div>
    </div>
}
