<div
    class="container"
    [ngClass]="{
        'trigger-anim-left': toggleValue.name === this.toggleItems[0].name && allowAnim,
        'trigger-anim-right': toggleValue.name === this.toggleItems[1].name && allowAnim,
        grey: backgroundColor === 'grey'
    }"
>
    <div
        class="left toggle-item"
        [ngClass]="{
            'is-active': toggleValue.name === this.toggleItems[0].name
        }"
        (click)="toggled(this.toggleItems[0])"
        [innerHTML]="this.toggleItems[0].display"
    ></div>
    <div
        class="right toggle-item"
        [ngClass]="{
            'is-active': toggleValue.name === this.toggleItems[1].name
        }"
        (click)="toggled(this.toggleItems[1])"
        [innerHTML]="this.toggleItems[1].display"
    ></div>
</div>
