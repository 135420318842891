<div
    class="container"
    (mouseover)="cardIsHovered()"
    (mouseleave)="cardIsNoLongerHovered()"
    [ngClass]="{
        'is-active': isActive,
        'hide-shadow': hideShadow && isBestAlternative
    }"
>
    @if (data.hasBestAlternative) {
        <div class="best-alternative-label">
            <mat-icon class="material-icons-round">star_rate</mat-icon>
            <qtm-typography fontWeight="bold"> Best Alternative Of The Day </qtm-typography>
        </div>
    }
    <!-- first row of the card-->
    <div class="card-header">
        <!-- flight info -->
        <div class="flight-info">
            <!-- hours and flight level items -->
            <div class="flight-number">
                <qtm-typography fontWeight="black" fontSize="6">
                    {{ data.callSign }}
                </qtm-typography>
            </div>

            <!-- airports -->
            <div class="airports-codes-row">
                <qtm-typography fontWeight="black" fontSize="5">
                    {{ data!.startAirportCode }}
                </qtm-typography>
                <qtm-typography fontWeight="black" fontSize="5">
                    {{ data!.endAirportCode }}
                </qtm-typography>
            </div>
            <div class="hours-row">
                <qtm-typography fontWeight="bold" fontSize="6">
                    {{ data!.startDate | formatFlightHour }}
                </qtm-typography>
                <qtm-typography fontWeight="bold" fontSize="6">
                    {{ data!.endDate | formatFlightHour }}
                </qtm-typography>
            </div>
        </div>

        <!-- ATR20 part -->
        <div class="atr20">
            <div class="legend">
                <qtm-typography fontSize="7" fontWeight="bold"> ATR<sub>20</sub> </qtm-typography>
            </div>
            <div class="value">
                <qtm-typography fontSize="4" fontWeight="bold">
                    {{ displayedAlternative!.totalCcf | convertToPicoKelvin }}
                </qtm-typography>
                <qtm-typography fontSize="9" fontWeight="bold"> pK </qtm-typography>
            </div>
            <div class="initial-fl">
                <qtm-typography fontSize="9" fontWeight="regular"> Than initial FL{{ data.flightLevel }} </qtm-typography>
            </div>
        </div>
    </div>

    <!-- flights levels table -->
    <div class="flight-levels-table">
        <!-- virgule pour mettre en commun style classes -->
        <table aria-label="Flight alternatives and its emissions">
            <colgroup class="columns"></colgroup>
            <thead>
                <tr class="header-row">
                    <th scope="col"></th>
                    <th scope="col">
                        <qtm-typography fontWeight="bold" fontSize="7"> EXTRA FUEL </qtm-typography>
                    </th>
                    <th scope="col">
                        <qtm-typography fontWeight="bold" fontSize="7"> ATR<sub>20</sub> </qtm-typography>
                    </th>
                </tr>
            </thead>
            <tbody>
                @for (alternative of data.alternatives; track alternative) {
                    <tr
                        (mouseover)="displayedAlternative = alternative"
                        (mouseleave)="displayedAlternative = bestAlternative"
                        class="flight-level-row"
                        [ngClass]="{
                            'best-alternative': alternative.isBestAlternative && displayedAlternative === alternative
                        }"
                    >
                        <td class="flight-level-col">
                            <qtm-typography fontWeight="normal" fontSize="6">
                                {{ alternative.flightLevel }}
                            </qtm-typography>
                        </td>
                        <td class="table-values">
                            <qtm-typography fontWeight="normal" fontSize="6">
                                {{ +alternative.fuelRatio > 0 ? "+" + alternative.fuelRatio : alternative.fuelRatio }}%
                            </qtm-typography>
                        </td>
                        <td class="table-values">
                            <qtm-typography fontWeight="normal" fontSize="6">
                                {{ +alternative.ccfRatio > 0 ? "+" + alternative.ccfRatio : alternative.ccfRatio }}%
                            </qtm-typography>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    </div>
</div>
