<div
    class="container"
    [ngClass]="{
        'is-loading': isLoading,
        'coming-flight-full-screen': isComingFlightFullScreen
    }"
>
    @if ((errors !== null || this.cards.length === 0) && !isLoading) {
        <div class="error">
            @if (errors !== null) {
                <ffp-no-data
                    title="Oops, we had an issue!"
                    service="forecast"
                    [errorCode]="errors.status"
                    [button]="true"
                    buttonText="Refresh"
                    buttonIcon="refresh"
                    (buttonCallback)="loadData()"
                >
                </ffp-no-data>
            }
            @if (!this.cards.length) {
                <ffp-no-data
                    title="No big hits found today"
                    description="You are doing a great job,<br> we don’t have major opportunities <br>to suggest."
                    service="forecast"
                    [button]="false"
                ></ffp-no-data>
            }
        </div>
    }

    @if (isLoading) {
        <div class="cards-container" [ngClass]="{ center: this.cards.length === 1 }">
            <div class="card loading-element"></div>
            <div class="card big-card loading-element"></div>
            <div class="card loading-element"></div>
        </div>
    }
    @if (!isLoading && this.cards.length > 0) {
        <div class="cards-container" [ngClass]="{ center: this.cards.length === 1 }">
            @for (card of this.cards; track card) {
                <ffp-forecast-card [data]="card"></ffp-forecast-card>
            }
            @if (this.cards.length === 2) {
                <ffp-forecast-card class="hidden" [data]="this.cards[0]"></ffp-forecast-card>
            }
        </div>
    }
    <div class="coming-flights-container">
        @if (!isLoading) {
            <ffp-forecast-coming-flight [flights]="flights" (triggerFullScreen)="handleComingFlightFullScreen($event)"></ffp-forecast-coming-flight>
        }
        @if (isLoading) {
            <div class="coming-flight-loader">
                <div class="header">
                    <div class="left">
                        <div class="today-flights loading-element"></div>
                        <div class="navigation">
                            <div class="navigation-item loading-element"></div>
                            <div class="navigation-item loading-element"></div>
                        </div>
                    </div>
                    <div class="time-management loading-element"></div>
                </div>
                <div class="calendar loading-element"></div>
            </div>
        }
    </div>
</div>
