<mat-dialog-content class="container">
    <div class="title-container" mat-dialog-title>
        <div class="title-content">
            <qtm-typography fontSize="6" fontWeight="bold"> Filters </qtm-typography>
        </div>
        <mat-icon (click)="onClose()">close</mat-icon>
    </div>

    <div class="filter-container">
        <div class="title">
            <qtm-typography fontSize="5" fontWeight="bold"> Immatriculation </qtm-typography>
        </div>
        <div class="buttons-container">
            @for (imma of getKeys(this.immatriculationsTemp); track imma) {
                <mat-checkbox
                    class="filter-checkbox"
                    (click)="onClick(imma, 'immatriculations')"
                    [ngClass]="{
                        active: this.immatriculationsTemp.get(imma) && immatriculationsTemp.size !== 1,
                        inactive: immatriculationsTemp.size === 1
                    }"
                    [disabled]="immatriculationsTemp.size === 1"
                >
                    <qtm-typography fontSize="8">
                        {{ imma }}
                    </qtm-typography>
                </mat-checkbox>
            }
        </div>
    </div>

    <div class="filter-container">
        <div class="title">
            <qtm-typography fontSize="5" fontWeight="bold"> Aircraft Type </qtm-typography>
        </div>
        <div class="buttons-container">
            @for (actype of getKeys(this.aircraftTypesTemp); track actype) {
                <div>
                    <mat-checkbox
                        class="filter-checkbox"
                        (click)="onClick(actype, 'aircraft type')"
                        [ngClass]="{
                            active: this.aircraftTypesTemp.get(actype) && aircraftTypesTemp.size !== 1,
                            inactive: aircraftTypesTemp.size === 1
                        }"
                        [disabled]="aircraftTypesTemp.size === 1"
                    >
                        <qtm-typography fontSize="8">
                            {{ actype }}
                        </qtm-typography>
                    </mat-checkbox>
                </div>
            }
        </div>
    </div>

    <div class="filter-container">
        <div class="title">
            <qtm-typography fontSize="5" fontWeight="bold"> Flight Level </qtm-typography>
        </div>
        <div class="slider-header">
            <qtm-typography fontSize="9"> Slide to select the range of flight level you want to see </qtm-typography>
            @if (minFlightLevel !== maxFlightLevel) {
                <div class="selected-range">
                    <qtm-typography fontSize="9" fontWeight="bold">
                        {{ minFlightLevelSelectedTemp }} -
                        {{ maxFlightLevelSelectedTemp }}
                    </qtm-typography>
                </div>
            }
        </div>

        @if (!popUpIsLoading) {
            <div class="custom-slider">
                <mat-slider
                    [min]="minFlightLevel"
                    [max]="maxFlightLevel"
                    #matSlider
                    [step]="sliderStep"
                    [disabled]="this.maxFlightLevel === this.minFlightLevel"
                    showTickMarks
                >
                    <input [(value)]="minFlightLevelSelectedTemp" matSliderStartThumb />
                    <input [(value)]="maxFlightLevelSelectedTemp" matSliderEndThumb />
                </mat-slider>
                <div class="slider-tick-values">
                    <qtm-typography fontSize="9">
                        {{ minFlightLevel }}
                    </qtm-typography>
                    @if (sliderEvenSteps) {
                        <qtm-typography fontSize="9">
                            {{ middleFlightLevel }}
                        </qtm-typography>
                    }
                    <qtm-typography fontSize="9">
                        {{ maxFlightLevel }}
                    </qtm-typography>
                </div>
            </div>
        }
    </div>

    <div class="down-buttons-container">
        <ffp-button type="primary" gradient size="small" (ffpClick)="onDisplayFlights()">
            <mat-icon [inline]="true">flight</mat-icon>
            <qtm-typography fontSize="7" fontWeight="bold"> Display flights </qtm-typography>
        </ffp-button>
        <ffp-button type="secondary" size="small" ghost (ffpClick)="clearFilter()">
            <qtm-typography fontSize="6" fontWeight="bold"> Clear </qtm-typography>
        </ffp-button>
    </div>
</mat-dialog-content>
