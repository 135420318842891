import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RetryInterceptor } from './core/interceptors/retry.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AdministrationModule } from './features/administration/administration.module';
import { ForecastModule } from './features/forecast/forecast.module';
import { ClimateMapModule } from './features/climate-map/climate-map.module';
import { SharedModule } from './shared/shared.module';
import { QtmComponentLibraryModule } from '@qtm/angular';
import { ClimateAnalyticsModule } from './features/climate-analytics/climate-analytics.module';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService,
    ProtectedResourceScopes,
} from '@azure/msal-angular';
import { apiConfig, msalConfig } from '../../auth-config';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from './shared/utils/format-datepicker';
import { FileUploadModule } from 'ng2-file-upload';
import { LocalInterceptor } from './core/interceptors/local.interceptor';

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        loginFailedRoute: window.location.origin,
    };
}

export const protectedResources = {
    MainStreetContact: {
        endpoint: environment.backendApi + '/api/*',
        scopes: apiConfig.b2cScopes,
    },
};

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, (string | ProtectedResourceScopes)[]>([
        [protectedResources.MainStreetContact.endpoint, protectedResources.MainStreetContact.scopes],
    ]);
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.appUrl.split('//')[1].split(':')[0], // localhost || flights-footprint[.dev].kaas.thalesdigital.io
    },
    position: 'bottom',
    theme: 'classic',
    palette: {
        popup: {
            background: 'rgba(1, 41, 64, 0.9)',
            text: '#ffffff',
            link: '#ffffff',
        },
        button: {
            background: '#13C7B4 !important',
            text: '#ffffff',
            border: 'transparent',
        },
    },
    type: 'info',
    content: {
        message: `We use cookies on this site to enhance your user experience.
            By clicking any link on this page you are giving your consent for us to set cookies.`,
        dismiss: 'OK, I AGREE',
        link: 'Give me more info',
        href: '/',
    },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        // Angular
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // Authentication
        MsalModule,
        // Green Aviation
        SharedModule,
        CoreModule,
        AdministrationModule,
        ForecastModule,
        ClimateMapModule,
        FileUploadModule,
        ClimateAnalyticsModule,
        // Cookies
        MatDialogModule,
        MatIconModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        QtmComponentLibraryModule,
    ],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RetryInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocalInterceptor,
            multi: true,
        },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
