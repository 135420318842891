<div class="container">
    <img
        [src]="'assets/shapes/analytics-card' + (emissions.monthDelta <= 0 ? '-negative.svg' : '-positive.svg')"
        alt=""
        [ngStyle]="{
            display: emissions.monthDelta === 0 || !emissions.monthDelta ? 'none' : 'inherit'
        }"
    />
    <div class="header">
        <div class="city-pair">
            <qtm-typography fontWeight="black" fontSize="6">
                {{ data.startAirportCode }}
            </qtm-typography>
            <qtm-typography fontWeight="black" fontSize="6">
                {{ data.endAirportCode }}
            </qtm-typography>
        </div>
        <div class="nb-flights">
            <qtm-typography fontWeight="bold" fontSize="9">
                {{ data.nbFlights + (data.nbFlights > 1 ? " flights" : " flight") }}
            </qtm-typography>
        </div>
    </div>
    <div class="emissions-info" #ref>
        <qtm-typography fontWeight="normal" fontSize="9" class="title"> AVERAGE CLIMATE IMPACT </qtm-typography>
        <div class="atr20">
            <div class="value">
                <div class="raw-value">
                    @if (coreService.emissionMeasure === "Ccf") {
                        <qtm-typography fontWeight="black" fontSize="6">
                            {{ this.data.meanCcf | convertToPicoKelvin | formatNumber }}
                        </qtm-typography>
                        <qtm-typography fontWeight="light" fontSize="9" class="raw-value"> pK </qtm-typography>
                    } @else {
                        <qtm-typography fontWeight="black" fontSize="6">
                            {{ (this.data.meanGwp100 | autoConvert: "g" : "value")! | formatNumber }}
                        </qtm-typography>
                        <qtm-typography fontWeight="light" fontSize="9" class="raw-value">
                            {{ this.data.meanGwp100 | autoConvert: "g" : "unit" }}
                            CO<sub>2</sub>-eq
                        </qtm-typography>
                    }
                </div>
                @if (emissions.monthDelta !== null && emissions.monthDelta !== 0) {
                    <ffp-data-delta [value]="emissions.monthDelta">
                        {{ emissions.monthDelta > 0 ? "+" + emissions.monthDelta : emissions.monthDelta }}%
                    </ffp-data-delta>
                }
            </div>

            @if (emissions.monthDelta !== null) {
                <qtm-typography fontSize="9" class="label">
                    your warming impact is
                    {{ emissions.monthDelta <= 0 ? "lower" : "higher" }}
                    compared to last month
                </qtm-typography>
            }
            @if (!emissions.monthDelta && emissions.monthDelta !== 0) {
                <qtm-typography fontSize="9" class="label"> we are still gathering your data to compare it to the previous month </qtm-typography>
            }
        </div>
        <div class="consumption-details">
            <ffp-consumption-details
                [data]="values"
                [svgId]="data.startAirportCode + '-' + data.endAirportCode"
                [parentWidth]="ref.clientWidth"
                [tooltip]="true"
            ></ffp-consumption-details>
        </div>
        <div class="bottom">
            @if (emissions.potentialSaving && coreService.emissionMeasure === "Ccf") {
                <qtm-typography fontWeight="normal" fontSize="9">
                    To go further, optimisations possible
                    <strong> {{ potentialSavingRatio + "%" }} </strong> namely
                    <strong>
                        {{ emissions.potentialSaving | convertToPicoKelvin | formatNumber }}
                        pK</strong
                    >
                </qtm-typography>
            } @else if (emissions.potentialSaving && coreService.emissionMeasure === "Gwp100") {
                <qtm-typography fontWeight="normal" fontSize="9">
                    To go further, optimisations possible
                    <strong> {{ potentialSavingRatio + "%" }} </strong> namely
                    <strong> {{ emissions.potentialSaving / 1000 | formatNumber }} kg CO<sub>2</sub>-eq</strong>
                </qtm-typography>
            }
            @if (!emissions.potentialSaving) {
                <qtm-typography fontWeight="normal" fontSize="9"> You are at your best, we don't have further suggestions for now! </qtm-typography>
            }
        </div>
    </div>
</div>
