<div
    class="container flight-tag"
    [ngClass]="{ 'is-hovered': isHovered, 'not-hovered': !isHovered }"
    (mouseover)="tagIsHovered()"
    (mouseleave)="tagIsNoLongerHovered()"
>
    @if (isPassed) {
        <div class="black-filter"></div>
    }
    @if (flight.initialFlight.hasBestAlternative) {
        <mat-icon class="best-alternative material-icons-round">star_rate </mat-icon>
    }
    <div class="content">
        @if (flight.initialFlight.realStartDate < coreService.getCurrentDate() && !isPassed) {
            <div class="in-progress-bg" [ngStyle]="{ width: 'calc(' + elapsedTimeWidth + '%)' }"></div>
        }
        <div class="flight-airport-info">
            <div class="start">
                <qtm-typography
                    component="body-2"
                    fontSize="5"
                    fontWeight="black"
                    class="airport-code"
                    [style]="{
                        color: sassHelperService.getProperty('ffp-' + color + '-130')
                    }"
                >
                    {{ flight.initialFlight.startAirportCode }}
                </qtm-typography>
                <qtm-typography component="body-2" fontSize="7" fontWeight="bold" class="time">
                    {{ flight.initialFlight.realStartDate | formatFlightHour }}
                </qtm-typography>
            </div>

            <div class="end">
                <qtm-typography
                    component="body-2"
                    fontSize="5"
                    fontWeight="black"
                    class="airport-code"
                    [style]="{
                        color: sassHelperService.getProperty('ffp-' + color + '-130')
                    }"
                >
                    {{ flight.initialFlight.endAirportCode }}
                </qtm-typography>
                <qtm-typography component="body-2" fontSize="7" fontWeight="bold" class="time">
                    {{ flight.initialFlight.realEndDate | formatFlightHour }}
                </qtm-typography>
            </div>
        </div>
        <div class="flight-info">
            <qtm-typography
                component="body-2"
                fontSize="7"
                fontWeight="bold"
                class="flight-number"
                [style]="{
                    color: sassHelperService.getProperty('ffp-' + color + '-170')
                }"
            >
                {{ flight.initialFlight.callSign }}
            </qtm-typography>
            <qtm-typography component="body-2" fontSize="8" fontWeight="bold" class="flight-level">
                {{ "FL" + flight!.initialFlight.cruiseFlightLevel }}
            </qtm-typography>
        </div>
    </div>
</div>
