import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy } from '@angular/core';
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper';

@Component({
    selector: 'ffp-swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiperComponent implements AfterViewChecked, AfterViewInit, OnDestroy {
    // add all the options as optional settings and use them to create an options object
    @Input() config: SwiperOptions = {};

    @Input() set initialize(value: boolean) {
        this.shouldInitialize = this.initialized ? false : value;
    }

    swiper: Swiper | undefined;

    private swiperWrapper: ParentNode | undefined;
    private slideCount = 0;
    private initialized = false;
    private shouldInitialize = true;

    constructor(
        private elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngAfterViewInit(): void {
        if (this.shouldInitialize) {
            this.setup();
        }
    }

    setup(): void {
        if (!this.swiper) {
            if (!Object.keys(this.config).includes('module')) {
                this.config['modules'] = [];
            }
            if (Object.keys(this.config).includes('pagination')) {
                this.config['modules']?.push(Pagination);
            }
            if (Object.keys(this.config).includes('navigation')) {
                this.config['modules']?.push(Navigation);
            }

            // if rendered on server querySelector is undefined
            if (this.elementRef.nativeElement.querySelector) {
                this.swiperWrapper = this.elementRef.nativeElement.querySelector('.swiper-wrapper');
                this.slideCount = this.swiperWrapper?.childElementCount || 0;
                this.swiper = new Swiper(this.elementRef.nativeElement.querySelector('swiper > div'), this.config);
                this.changeDetectorRef.detectChanges();
            }

            this.shouldInitialize = false;
        }
    }

    ngAfterViewChecked(): void {
        if (this.shouldInitialize) {
            this.setup();
        }

        if (this.swiperWrapper && this.slideCount !== this.swiperWrapper.childElementCount) {
            this.slideCount = this.swiperWrapper.childElementCount;
            this.swiper?.update();
        }
    }

    ngOnDestroy(): void {
        this.swiper?.destroy(true, true);
    }
}
